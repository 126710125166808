 <template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      dense
    >
      <span
        class="circle mx-1"
        style="cursor: pointer"
        @click="$router.push('/')"
      >
      </span>
      <span
        class="ml-n9 mr-5"
        style="cursor: pointer"
        @click="$router.push('/')"
      >
        <v-img
          src="./assets/KIT_LOGO.png"
          style="width: 25px"
          background-color="white"
        >
        </v-img>
      </span>
      <v-app-bar-title>
        <span style="cursor: pointer" @click="$router.push('/')">
          臺灣幼兒發展調查資料庫
        </span>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <span
        style="cursor: pointer"
        @click="$router.push('/Profile')"
        v-if="userdata.nickname!=null"
      >
        {{userdata.nickname}}
      </span>

      <v-divider
        vertical
        class="mx-4"
        v-if="userdata.nickname!=null"
      ></v-divider>

      <v-btn
        text
        to="/shopcart"
        v-if="userdata.nickname!=null"
      >
          <v-icon left>mdi-cart-outline</v-icon>
          我的資料
      </v-btn>

      <v-divider
        vertical
        class="mx-4"
        v-if="userdata.nickname!=null"
      ></v-divider>

      <v-dialog
        v-model="dialog"
        max-width="700px"
        v-if="userdata.nickname == null"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            v-bind="attrs"
            v-on="on"
            dense
          >
            <v-icon class="mr-2">mdi-login</v-icon>
            <span>登入</span>
          </v-btn>
        </template>
        <v-sheet>
          <v-container
            fluid
            class="ma-0 pa-0"
          >
            <v-row
              no-gutters
              align="center"
            >
              <v-col
                cols="4"
              >
                  <v-img
                    src="./assets/KIT_LOGO.png"
                    gradient="to top right, rgba(255,255,255,.33), rgba(255,255,255,.7)"
                  >
                  </v-img>
                <!-- <v-card
                  :elevation="0"
                  tile
                >
                </v-card> -->
              </v-col>
              <v-col
                cols="8"
              >
                <v-card
                  :elevation="0"
                  tile
                >
                  <v-tabs
                    v-model="loginTab"
                    grow
                  >
                    <v-tab>
                      已有帳號登入
                    </v-tab>
                    <v-tab>
                      創建新的帳號
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items
                    v-model="loginTab"
                  >
                    <v-tab-item
                      transition="fade-transition" reverse-transition="fade-transition"
                    >
                      <v-card
                        class="pa-4"
                      >
                        <v-form
                          ref="loginForm"
                          v-model="loginValid"
                          lazy-validation
                          @submit.prevent="login"
                        >
                          <v-text-field
                            v-model="account"
                            :rules="accountRules"
                            label="帳號"
                            outlined
                            required
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="password"
                            label="密碼"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            outlined
                            required
                            dense
                          ></v-text-field>
                          <span
                            @click="forgetPassword"
                            style="cursor: pointer; color: #2d7535"
                          >
                            忘記密碼?
                          </span>
                          <v-spacer></v-spacer>
                          <v-btn
                            :disabled="!loginValid"
                            color="success"
                            @click.prevent="login"
                            type="submit"
                            class="mt-3"
                            block
                          >
                            登入
                          </v-btn>
                          <v-alert
                            :value="alertPlace == 'login'"
                            :type="alertType"
                            dense
                            outlined
                            class="mt-2 mb-n1"
                          >
                            {{alertMsg}}
                          </v-alert>
                        </v-form>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item
                      transition="fade-transition" reverse-transition="fade-transition"
                    >
                      <v-card
                        class="pa-4"
                      >
                        <v-form
                          ref="registerForm"
                          v-model="registerValid"
                          lazy-validation
                          @submit.prevent="register"
                        >
                          <v-text-field
                            v-model="username"
                            :rules="usernameRules"
                            label="帳號"
                            outlined
                            required
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="電子信箱(請使用和SRDA相同信箱)"
                            outlined
                            required
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="password"
                            :rules="passwordRules"
                            label="密碼"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            outlined
                            required
                            dense
                          ></v-text-field>
                          <v-checkbox
                            v-model="privacyCheckbox"
                            :rules="[v => !!v || '你必須同意隱私條款才能繼續!']"
                            required
                            dense
                            class="ma-0"
                          >
                            <template v-slot:label>
                              <div>
                                <template>
                                  <div class="text-center">
                                    <v-btn @click="privacyDialog = true">
                                      我同意此隱私條款
                                    </v-btn>
                                  </div>
                                </template>
                              </div>
                            </template>
                          </v-checkbox>
                          <v-card-text style="color: rgba(0, 0, 0, .7);">
                            請注意:您必須為SRDA一般會員，且會員身分仍在效期內才能創建新的帳號。
                          </v-card-text>
                          <v-btn
                            :disabled="!registerValid"
                            color="success"
                            @click.prevent="register"
                            type="submit"
                            block
                          >
                            註冊
                          </v-btn>
                          <v-alert
                            :value="alertPlace == 'register'"
                            :type="alertType"
                            dense
                            outlined
                            class="mt-2 mb-n1"
                          >
                            {{alertMsg}}
                          </v-alert>
                        </v-form>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-btn
        outlined
        dense
        v-if="userdata.nickname != null"
        @click="logout"
      >
        <v-icon class="mr-2">mdi-logout</v-icon>
        <span>登出</span>
      </v-btn>
      <template
        v-slot:extension
        v-if="userdata.nickname != null"
      >
        <v-tabs
          v-if="userdata.nickname != null"
          v-model="mainTab"
          grow
          light
          class="mainTab"
          style="background-color: #C6D033;"
        >
          <!-- background-color="transparent"
          text-color="basil" -->
          <v-tabs-slider color="transparent"></v-tabs-slider>
          <v-tab
            v-for="item in tabItems"
            :key="'Tab_'+item.index"
            class="font-weight-bold"
            :to="item.path"
          >
            {{item.name}}
          </v-tab>
        </v-tabs>
      </template>
      <v-dialog
      v-model="notification"
      width="800"
      >
        <v-card>
          <v-card-title>
            E-mail認證
          </v-card-title>
          <v-card-text>
            請查看您填寫的E-mail，確認有無收到認證信件，E-mail認證完成後才算註冊成功。
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="privacyDialog"
        width="800"
        height="600"
      >
      <v-card>
        <v-card-title>
            隱私條款
          </v-card-title>
          <v-card-text>
            感謝您光臨「臺灣幼兒發展調查資料庫跨波次平台」（以下簡稱本網站），為了幫助您瞭解本網站如何蒐集、應用及保護您所提供的個人資訊，請您務必詳細閱讀下列資訊：
              <br><b>※隱私權政策※</b><br>
              1、關於個人資料之蒐集、處理及利用方式:<br>
              單純在本網站的瀏覽及檔案下載行為，本網站並不會蒐集任何有關個人的身分資料。
              利用本網站所提供的各項線上申請服務，需申請人提供個人資料，包括姓名、身分證統一編號、聯絡電話、電子郵件信箱（e-mail）、戶籍地址等個人最新、最真實之資料。
              為提升本網站的服務品質，本網站會記錄使用者上站的IP位址、上網時間、於網站內所瀏覽的網頁等資料，這些資料係供本網站管理小組內部使用，作為本網站全體使用者行為和網站流量分析之用，並不會針對個別使用者進行分析。
              本網站有義務保護各申請人隱私，非經您本人同意（或符合以下情況），不會自行修改或刪除任何網路民眾個人資料及檔案：
              經由合法的途徑。
              保護或防衛相關網路民眾的權利或所有權。
              為保護本網站各相關單位之權益。
              本網站絕不會任意出售、交換、或出租任何您的個人資料給其他團體、個人或私人企業。但有下列情形者除外：
              配合司法單位合法的調查。
              配合相關職權機關依職務需要之調查或使用。
              基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理。
              <br>2、關於個人應盡之義務:<br>
              若您提供任何錯誤或不實的資料，本網站各相關單位有權拒絕您使用本網站之全部或部分服務。
              <br>3、資料之保護:<br>
              本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密切結書等書面約定，如有違反保密義務者，將會受到相關的法律處分。 如因業務需要而有必要委託本院相關單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
              <br>4、網站對外的相關連結:<br>
              本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用於本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
              <br>5、Cookie之使用:<br>
              本網站會在您電腦瀏覽器中寫入並讀取Cookie，若您不願意接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能因此使本網站部分功能無法正常執行。
              <br><b>※網站安全政策※</b><br>
              任何未經授權而企圖上載或更改本單位所提供的各項服務及相關資訊的行為，都是嚴厲禁止而且可能觸犯法律。
              為了網站安全的目的和確保這項服務能夠繼續服務所有的網路使用者，本網站提供了以下的安全保護措施：
              裝設防火牆設備，限制特定通訊埠的連結，防止非法入侵，以避免網站遭到非法使用，保障使用者的權益。
              使用網路入侵偵測系統，監控網路流量，並對有惡意企圖的行為進行阻隔。
              裝設掃毒軟體，提供使用者安全的網頁瀏覽環境。
              利用弱點偵測軟體，不定期針對網路系統弱點掃描，並予以補強修正。
              不定期模擬駭客攻擊，演練發生資訊安全事件時的系統回復程序，提供網站的持續營運。
              定期進行備份作業。
              定時依相關作業系統廠商或應用程式廠商的安全維護通知，更新適當的修補程式(PATCH)。
              若您對本網站的隱私權及網站安全政策有任何意見，歡迎隨時與我們聯絡。
              本網站之隱私權及網站安全政策將因應需求隨時進行修正，修正後的條款將刊登於本網站上。
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-main>
      <!-- <v-tabs
        v-if="userdata.nickname != null"
        v-model="mainTab"
        background-color="secondary"
        text-color="primary"
        grow
      >
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab
          v-for="item in tabItems"
          :key="'Tab_'+item.index"
          class="font-weight-bold"
          :to="item.path"
        >
          {{item.name}}
        </v-tab>
      </v-tabs> -->
      <v-container
        fluid
        background-color="transparent"
      >
        <!-- style="max-height: 90vh;" -->
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'App',

  data: () => ({
    apiURL: 'http://localhost:5000',
    mainTab: null,
    loginTab: null,
    dialog: false,
    showPassword: false,
    loginValid: true,
    registerValid: true,
    notification: false,

    username: null,
    account: null,
    email: null,
    password: null,

    alertPlace: '',
    alertType: null,
    alertMsg: '',

    privacyCheckbox: false,
    // userdata: {
    //   username: null
    // },
    usernameRules: [
      v => !!v || 'Username is required',
      v => (v && v.length < 15) || 'Username must be less than 15 characters'
    ],
    accountRules: [
      v => !!v || 'Account is required'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      // v => (v && v.length >= 8) || 'Password must be longer than 8 characters'
      v => /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) || '需含有至少大小寫英文字母、數字和特殊字元，長度8位以上'
    ],
    allTabItems: [
      {
        index: 0,
        name: '最新消息',
        path: '/',
        auth: 3
      },
      {
        index: 1,
        name: '資料匯入',
        path: '/DataImport',
        auth: 1
      },
      {
        index: 2,
        name: '資料查詢',
        path: '/search',
        auth: 2
      },
      {
        index: 3,
        name: '權限管理',
        path: '/admin',
        auth: 1
      },
      // {
      //   index: 5,
      //   name: '歷史紀錄',
      //   path: '/'
      // },
      {
        index: 6,
        name: '問題回報',
        path: '/ProblemReport',
        auth: 2
      }
      // {
      //   index: 7,
      //   name: '聯繫我們',
      //   path: '/ContactUs'
      // }
    ]
  }),
  // beforeMount () {
  //   this.loadInfo()
  // },
  computed: {
    tabItems: function () {
      return this.allTabItems.filter(word => word.auth >= this.userdata.auth)
    },
    ...mapState({
      userdata: state => state.userdata
    })
  },
  methods: {
    loadInfo () {
      const config = {
        url: '/api/personalApp/loadInfo',
        method: 'get'
      }
      // console.log('hi')
      axios(config)
        .then((res) => {
          // console.log(res.data.data)
          // this.userdata = res.data.data
          this.$store.commit('loadUserdata', res.data.data)
        })
        .catch(() => {
          this.$store.commit('loadUserdata', { nickname: null, auth: 3 })
        })
    },
    login () {
      if (this.$refs.loginForm.validate()) {
        const config = {
          url: '/api/loginApp/login',
          method: 'post',

          data: {
            username: this.account,
            password: this.password
          }
        }
        axios(config)
          .then((res) => {
            // console.log(res.data.message)
            this.alertPlace = 'login'
            this.alertType = 'success'
            this.alertMsg = res.data.message
            // this.userdata.nickname = this.account
            this.loadInfo()
            this.dialog = false
            // this.$router.go(0)
          })
          .catch((err) => {
            // console.log(err.response.data.message)
            this.alertPlace = 'login'
            this.alertType = 'error'
            this.alertMsg = err.response.data.message
            // console.log('ERR!!!')
          })
      }
    },
    logout () {
      const config = {
        url: '/api/loginApp/logout',
        method: 'post'
      }
      this.userdata.nickname = null
      axios(config)
        .then((res) => {
          // console.log('logout!!')
          this.alertPlace = ''
          // this.userdata = {
          //   nickname: null
          // }
          this.password = null
          this.loadInfo()

          this.$router.push('/')
        })
    },
    register () {
      if (this.$refs.registerForm.validate()) {
        const config = {
          url: '/api/loginApp/register',
          method: 'post',

          data: {
            username: this.username,
            password: this.password,
            email: this.email
          }
        }
        axios(config)
          .then((res) => {
            // console.log(res.data.message)
            this.alertPlace = 'register'
            this.alertType = 'success'
            this.alertMsg = res.data.message
            this.notification = true
          })
          .catch((err) => {
            console.log(err.response.data.message)
            this.alertPlace = 'register'
            this.alertType = 'error'
            this.alertMsg = err.response.data.message
            // console.log('ERR!!!')
          })
      }
    },
    forgetPassword () {
      this.$router.push('/forget')
      this.dialog = false
    }
  }
}
</script>

<style>
/* .v-tab:not(.v-tab--active)  {
  color: #2D7535!important;

} */
/* >>> .v-tab--active {
  color: #2D7535
}
>>> .v-tab:not(.v-tab--active) {
  color: #2D7535;
} */
.basil {
  background-color: #C6D033 !important;
}
.basil--text {
  color: #2D7535 !important;
}
.circle {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%
}
.diamond {
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: white;
  position: relative;
  top: -20px;
}
.diamond:after {
  content: '';
  position: absolute;
  left: -20px;
  top: 20px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color:  white;
}
.logo {
  max-height: 100%;
  width: 1vw;
  margin-bottom: -100px;
  padding-bottom: -10px;
}
#app {
  /* background-image: url('https://scontent.ftpe7-3.fna.fbcdn.net/v/t31.18172-8/12465938_539406982888206_5102129001026077151_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=ywEtmtgKUBUAX9JO0rK&tn=TKrYiZZRW55v75Rf&_nc_ht=scontent.ftpe7-3.fna&oh=81e7e153f6d44bd7839a61d20e71cac9&oe=612BB1A8'),
                    rgba(0, 0, 0, 0.3);
  background-repeat: no-repeat,
                     no-repeat;
  background-size: center/40% cover,
                   cover; */
                   /* url('https://scontent.ftpe7-3.fna.fbcdn.net/v/t31.18172-8/12465938_539406982888206_5102129001026077151_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=ywEtmtgKUBUAX9JO0rK&tn=TKrYiZZRW55v75Rf&_nc_ht=scontent.ftpe7-3.fna&oh=81e7e153f6d44bd7839a61d20e71cac9&oe=612BB1A8') */
  /* background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), center/40% url('https://scontent.ftpe7-3.fna.fbcdn.net/v/t31.18172-8/12465938_539406982888206_5102129001026077151_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=ywEtmtgKUBUAX9JO0rK&tn=TKrYiZZRW55v75Rf&_nc_ht=scontent.ftpe7-3.fna&oh=81e7e153f6d44bd7839a61d20e71cac9&oe=612BB1A8') */
  background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), right bottom/20% url('./assets/KIT_LOGO.png')
  /* background: center/40% url('https://scontent.ftpe7-3.fna.fbcdn.net/v/t31.18172-8/12465938_539406982888206_5102129001026077151_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=ywEtmtgKUBUAX9JO0rK&tn=TKrYiZZRW55v75Rf&_nc_ht=scontent.ftpe7-3.fna&oh=81e7e153f6d44bd7839a61d20e71cac9&oe=612BB1A8') */
   no-repeat !important;
  background-size: cover;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__prepend-outer,
.special {
  margin: auto;
}

</style>
